<template>
  <div>
    <div id="container">
      <div class="element">
        <div class="symbol">About Me</div>
        <div class="content1">
          <div class="myInfo">
            <div class="img">
              <img src="../../assets/images/hak.png" />
            </div>
            <div class="comment">
              name : <span>Lee seung Hak</span><br />
              age : <span>1992.01.07 </span><br />
              from : <span>Korea in Busan</span><br />
              certificate : <span>driver's license</span><br />
              education : <span>Busan ITWILL (6 months)</span><br />
              E-mail : <span>seunghak173@gmail.com</span>
            </div>
          </div>
        </div>
      </div>
      <div class="skills" @click="skills()">
        <div class="symbol" v-text="data1"></div>
      </div>
      <div class="element2 off">
        <div class="symbol">Skills</div>
        <div class="content2">
          <div class="bx">
            <p class="sector">Front-end</p>
            <div class="alltable">
              <div class="javascript">
                <span>JavaScript:::</span>
                <table class="table1">
                  <tr>
                    <td style="backgroundColor:#f5e55b"></td>
                    <td style="backgroundColor:#f5c95b"></td>
                    <td style="backgroundColor:#f5b25b"></td>
                    <td style="backgroundColor:#f5915b"></td>
                    <td></td>
                  </tr>
                </table>
              </div>
              <div class="javascript">
                <span>jQuery:::</span>
                <table class="table1">
                  <tr>
                    <td style="backgroundColor:#f5e55b"></td>
                    <td style="backgroundColor:#f5c95b"></td>
                    <td style="backgroundColor:#f5b25b"></td>
                    <td></td>
                    <td></td>
                  </tr>
                </table>
              </div>
              <div class="javascript">
                <span>Vue-Js:::</span>
                <table class="table1">
                  <tr>
                    <td style="backgroundColor:#f5e55b"></td>
                    <td style="backgroundColor:#f5c95b"></td>
                    <td style="backgroundColor:#f5b25b"></td>
                    <td style="backgroundColor:#f5915b"></td>
                    <td></td>
                  </tr>
                </table>
              </div>
              <div class="javascript">
                <span>CSS3:::</span>
                <table class="table1">
                  <tr>
                    <td style="backgroundColor:#f5e55b"></td>
                    <td style="backgroundColor:#f5c95b"></td>
                    <td style="backgroundColor:#f5b25b"></td>
                    <td style="backgroundColor:#f5915b"></td>
                    <td></td>
                  </tr>
                </table>
              </div>
              <div class="javascript">
                <span>NodeJs:::</span>
                <table class="table1">
                  <tr>
                    <td style="backgroundColor:#f5e55b"></td>
                    <td style="backgroundColor:#f5c95b"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </table>
              </div>
              <div class="javascript">
                <span>ThreeJs:::</span>
                <table class="table1">
                  <tr>
                    <td style="backgroundColor:#f5e55b"></td>
                    <td style="backgroundColor:#f5c95b"></td>
                    <td style="backgroundColor:#f5b25b"></td>
                    <td></td>
                    <td></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div class="bx">
            <p class="sector">Back-end</p>
            <div class="alltable">
              <div class="javascript">
                <span>JAVA:::</span>
                <table class="table1">
                  <tr>
                    <td style="backgroundColor:#f5e55b"></td>
                    <td style="backgroundColor:#f5c95b"></td>
                    <td style="backgroundColor:#f5b25b"></td>
                    <td style="backgroundColor:#f5915b"></td>
                    <td style="backgroundColor:#ff4d2e"></td>
                  </tr>
                </table>
              </div>
              <div class="javascript">
                <span>JSP:::</span>
                <table class="table1">
                  <tr>
                    <td style="backgroundColor:#f5e55b"></td>
                    <td style="backgroundColor:#f5c95b"></td>
                    <td style="backgroundColor:#f5b25b"></td>
                    <td style="backgroundColor:#f5915b"></td>
                    <td style="backgroundColor:#ff4d2e"></td>
                  </tr>
                </table>
              </div>
              <div class="javascript">
                <span>Spring:::</span>
                <table class="table1">
                  <tr>
                    <td style="backgroundColor:#f5e55b"></td>
                    <td style="backgroundColor:#f5c95b"></td>
                    <td style="backgroundColor:#f5b25b"></td>
                    <td></td>
                    <td></td>
                  </tr>
                </table>
              </div>
              <div class="javascript">
                <span>Nodejs:::</span>
                <table class="table1">
                  <tr>
                    <td style="backgroundColor:#f5e55b"></td>
                    <td style="backgroundColor:#f5c95b"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div class="bx">
            <p class="sector">Database</p>
            <div class="alltable">
              <div class="javascript">
                <span>Oracle:::</span>
                <table class="table1">
                  <tr>
                    <td style="backgroundColor:#f5e55b"></td>
                    <td style="backgroundColor:#f5c95b"></td>
                    <td style="backgroundColor:#f5b25b"></td>
                    <td style="backgroundColor:#f5915b"></td>
                    <td></td>
                  </tr>
                </table>
              </div>
              <div class="javascript">
                <span>Mysql:::</span>
                <table class="table1">
                  <tr>
                    <td style="backgroundColor:#f5e55b"></td>
                    <td style="backgroundColor:#f5c95b"></td>
                    <td style="backgroundColor:#f5b25b"></td>
                    <td style="backgroundColor:#f5915b"></td>
                    <td></td>
                  </tr>
                </table>
              </div>
              <div class="javascript">
                <span>Firebase(NoSQL):::</span>
                <table class="table1">
                  <tr>
                    <td style="backgroundColor:#f5e55b"></td>
                    <td style="backgroundColor:#f5c95b"></td>
                    <td style="backgroundColor:#f5b25b"></td>
                    <td style="backgroundColor:#f5915b"></td>
                    <td style="backgroundColor:#ff4d2e"></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div class="bx">
            <p class="sector">etc</p>
            <span>GitHub, Gitlab, </span>
            <span>MyBatis, </span>
            <span>Apache Tomcat, </span>
            <span>Firebase</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from "three";
import {
  CSS3DRenderer,
  CSS3DObject
} from "three/examples/jsm/renderers/CSS3DRenderer";
//Caemra , scene, rendere1, rendere2
var camera, scene, renderer, renderer1, container, element, element1, skills;
var mouseX = 0,
  mouseY = 0;
var windowHalfX = window.innerWidth / 2;
var windowHalfY = window.innerHeight / 2;
var objects = [];
var targets = { table: [] };

export default {
  data() {
    return {
      data1: "Skills"
    };
  },
  mounted() {
    this.init();
    this.animate();
  },
  methods: {
    init() {
      container = document.getElementById("container");

      camera = new THREE.PerspectiveCamera(
        20,
        window.innerWidth / (window.innerHeight / 2),
        1,
        500
      );
      camera.position.z = 4000;
      scene = new THREE.Scene();

      //element Object
      element = document.querySelector(".element");
      element.style.backgroundColor = "rgba(136,136,136,0.3)";

      var object = new CSS3DObject(element);
      object.position.x = 0;
      object.position.y = 200;
      scene.add(object);

      element1 = document.querySelector(".element2");
      element1.style.backgroundColor = "rgba(51,51,102,0.3)";

      var object2 = new CSS3DObject(element1);
      object2.position.x = 0;
      object2.position.y = 200;
      scene.add(object2);

      //skills Object
      skills = document.querySelector(".skills");
      skills.style.backgroundColor = "rgba(51,51,102,0.3)";

      var object1 = new CSS3DObject(skills);
      object1.position.x = 0;
      object1.position.y = -280;
      scene.add(object1);

      targets.table.push(object);
      renderer = new CSS3DRenderer();
      renderer.setSize(window.innerWidth, window.innerHeight);
      container.appendChild(renderer.domElement);

      document.addEventListener("mousemove", this.onDocumentMouseMove, false);
      window.addEventListener("resize", this.onWindowResize, false);
    },

    onWindowResize() {
      windowHalfX = window.innerWidth;
      windowHalfY = window.innerHeight;

      camera.aspect = window.innerWidth / window.innerHeight;

      renderer.setSize(window.innerWidth, window.innerHeight);
    },
    onDocumentMouseMove(event) {
      mouseX = event.clientX - windowHalfX;
      mouseY = event.clientY - windowHalfY;
    },
    animate() {
      requestAnimationFrame(this.animate);
      this.render();
    },
    render() {
      camera.position.x += (mouseX - camera.position.x) * 0.05;
      camera.position.y += (-mouseY - camera.position.y) * 0.05;

      camera.lookAt(scene.position);
      renderer.render(scene, camera);
    },
    skills() {
      var element1 = document.querySelector(".element");

      var element2 = document.querySelector(".element2");
      if (element2.classList.contains("off")) {
        element2.classList.remove("off");
        element1.classList.add("off");
        skills.style.backgroundColor = "rgba(136,136,136,0.3)";
        this.data1 = "About";
      } else {
        element1.classList.remove("off");
        element2.classList.add("off");
        skills.style.backgroundColor = "rgba(51,51,102,0.3)";
        this.data1 = "Skills";
      }
    }
  }
};
</script>

<style scoped>
canvas {
  display: block;
}

a,
input,
select {
  pointer-events: auto;
}
#container {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
}

.element {
  animation: slidein 3s;
  display: block;
  position: relative;
  width: 100%;
  box-shadow: 0px 0px 12px rgba(136, 136, 136, 0.5);
  border: 1px solid rgba(136, 136, 136, 0.25);
  font-family: Helvetica, sans-serif;
  text-align: center;
  line-height: normal;
  cursor: default;
}

.element:hover {
  box-shadow: 0px 0px 12px rgba(136, 136, 136, 0.8);
  border: 1px solid rgba(136, 136, 136, 1);
}

.element .symbol {
  position: relative;
  top: 10px;
  left: 0px;
  right: 0px;
  font-size: 50px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.75);
  text-shadow: 0 0 10px rgba(255, 255, 255, 1);
}
/* element2 */
.element2 {
  animation: slidein 3s;
  position: relative;
  width: 100%;
  box-shadow: 0px 0px 12px rgba(51, 51, 102, 0.5);
  border: 1px solid rgba(51, 51, 102, 0.25);
  font-family: Helvetica, sans-serif;
  text-align: center;
  line-height: normal;
  cursor: default;
}

.element2:hover {
  box-shadow: 0px 0px 12px rgba(51, 51, 102, 0.8);
  border: 1px solid rgba(51, 51, 102, 1);
}

.element2 .symbol {
  position: relative;
  top: 10px;
  left: 0px;
  right: 0px;
  font-size: 50px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.75);
  text-shadow: 0 0 10px rgba(255, 255, 255, 1);
}
/* element2 */

@keyframes slidein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.myInfo {
  padding: 50px 50px;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
.myInfo .img {
  width: 700px;
  overflow: hidden;
}

.myInfo .img img {
  width: 100%;
  bottom: 0;
  /* position: absolute; */
  left: 0;
  filter: grayscale(80%) drop-shadow(16px 16px 20px black);
}

.myInfo .comment {
  width: 800px;
  font-size: 35px;
  letter-spacing: 1;
  text-transform: uppercase;
  text-align: left;
  color: black;
}
.myInfo .comment span {
  display: inline-block;
  margin-top: 19px;
  text-transform: none;
  color: white;
  font-size: 30px;
}

.skills:hover {
  cursor: pointer;
}

.skills {
  width: 15%;
  height: 100px;
  line-height: 80px;
  box-shadow: 0px 0px 12px rgba(136, 136, 136, 0.5);
  border: 1px solid rgba(136, 136, 136, 0.25);
  font-family: Helvetica, sans-serif;
  text-align: center;
  cursor: default;
}

.skills:hover {
  box-shadow: 0px 0px 12px rgba(136, 136, 136, 0.8);
  border: 1px solid rgba(136, 136, 136, 1);
}

.skills .symbol {
  position: relative;
  top: 10px;
  left: 0px;
  right: 0px;
  font-size: 50px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.75);
  text-shadow: 0 0 10px rgba(255, 255, 255, 1);
}

.skillInfo {
  margin: 100px auto;
  position: fixed;
  z-index: 155;
  background-color: teal;
  width: 80%;
  height: 300px;
}
.off {
  display: none;
}
.content2 {
  position: relative;
  display: flex;
  padding: 28px 40px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.content2 .bx {
  position: relative;
  width: 400px;
  height: 300px;
  border: 1px solid white;
  margin-bottom: 30px;
}
.content2 .bx .sector {
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 500;
  color: rgba(230, 230, 210, 0.5);
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
}
.javascript {
  font-size: 23px;
  margin-bottom: 5px;
}
.table1 {
  display: inline-block;
  width: 110px;
  height: 21px;
}
.table1 td {
  width: 30px;
  height: 20px;
  background-color: white;
}

@media (max-width: 1657px) {
  .myInfo .comment {
    font-size: 30px;
  }
  .myInfo .comment span {
    font-size: 27px;
  }
}

@media (max-width: 1600px) {
  .myInfo .comment {
    text-align: center;
  }
}

@media (max-width: 1502px) {
  .element {
    width: 1600px;
  }
  .myInfo .img {
    width: 600px;
  }
}

@media (max-width: 900px) {
  .element {
    width: 950px;
  }
  .content2 .bx {
    width: 300px;
  }
  .skills .symbol {
    font-size: 45px;
  }
}

@media (max-width: 813px) {
  .skills {
    height: 80px;
    line-height: 60px;
  }
  .skills .symbol {
    font-size: 35px;
  }
}

@media (max-width: 687px) {
  .content2 .bx {
    width: 250px;
  }
  .javascript {
    font-size: 20px;
  }
  .table1 td {
    width: 21px;
    height: 17px;
  }
}

@media (max-width: 643px) {
  .element {
    width: 750px;
  }
  .skills {
    height: 80px;
    line-height: 60px;
  }
  .skills .symbol {
    font-size: 25px;
  }
}

@media (max-width: 586px) {
  .content2 .bx {
    width: 100%;
  }

  .alltable {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .sector {
    margin: 5px 5px;
  }

  .content2 .bx:nth-child(1) {
    height: 190px;
  }

  .content2 .bx:nth-child(2) {
    height: 150px;
  }
  .content2 .bx:nth-child(3) {
    height: 150px;
  }
  .content2 .bx:nth-child(4) {
    height: 90px;
  }
}

@media (max-width: 455px) {
  .element {
    width: 600px;
  }
  .myInfo .comment {
    font-size: 25px;
  }
  .skills {
    width: 90px;
    height: 80px;
    line-height: 60px;
  }
  .skills .symbol {
    font-size: 25px;
  }
  .content2 .bx:nth-child(1) {
    height: 220px;
  }
  .content2 .bx:nth-child(2) {
    height: 160px;
  }
}
</style>
